import merge from 'lodash/merge';
import {
  createTheme as createStyled,
  responsiveFontSizes,
} from '@mui/material/styles';
import type { Theme, ThemeOptions } from '@mui/material';
import { THEMES } from '../constants';
import { lightShadows, darkShadows } from './shadows';
import { metbusDark, metbusLight } from './metbus';

interface ThemeConfig {
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme?: string;
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px',
        },
      },
    },
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem',
    },
    overline: {
      fontWeight: 600,
    },
  },
};

const themesOptions: Record<string, ThemeOptions> = {
  [THEMES.METBUSLIGHT]: {
    shadows: lightShadows,
    ...metbusLight,
  },
  [THEMES.METBUSDARK]: {
    shadows: darkShadows,
    ...metbusDark,
  },
};

export const createTheme = (config: ThemeConfig): Theme => {
  const themeOptions = themesOptions[config.theme ?? THEMES.METBUSLIGHT];

  // console.warn(new Error(`The theme ${config.theme} is not valid`));
  // themeOptions = themesOptions[THEMES.METBUSLIGHT];

  let theme = createStyled(
    merge(
      {},
      baseOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 16,
          },
        }),
      }
      // {
      // typography: {
      //   fontFamily: `${config.typography}, sans-serif`,
      // },
      //}
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
