import client from 'api/client';
import { AxiosResponse } from 'axios';
import {
  DriverPeriodResource,
  DriversWithDealsResource,
  DriversWithOvertimeDealResource,
} from 'src/pages/deals/types/drivers';
import {
  IDealFilters,
  OvertimeDealInput,
  SignOvertimeDealInput,
} from 'src/types/filters';
import { IFilters } from 'src/types/filters';
import { formatDateApi } from 'src/utils/datetimeHelper';
import { DriverResource } from 'types/drivers';

export function getEmployees(
  includeSettled: boolean
): Promise<AxiosResponse<DriverResource[]>> {
  return client.get<DriverResource[]>(
    `/api/Drivers/GetAllDrivers?includeSettled=${
      includeSettled ? 'true' : 'false'
    }`
  );
}

export function getDriversWithDeals(
  filters: IDealFilters
): Promise<AxiosResponse<DriversWithDealsResource>> {
  return client.post<DriversWithDealsResource>(
    '/api/Drivers/GetDriversWithDeals',
    {
      ruts: filters.ruts.length > 0 ? filters.ruts : null,
      rol: 0,
      isActive: filters.isActive,
    }
  );
}

export function getDriverWithAllDeals(
  id: string
): Promise<AxiosResponse<DriversWithOvertimeDealResource>> {
  return client.get<DriversWithOvertimeDealResource>(
    '/api/Drivers/GetDriverWithAllDeals',
    {
      params: {
        id: id,
      },
    }
  );
}

export function createOvertimeDeals(
  overtimeDealInput: OvertimeDealInput
): Promise<AxiosResponse> {
  return client.post('/api/Drivers/CreateOvertimeDeals', overtimeDealInput);
}

export const getDealPeriods = (): Promise<
  AxiosResponse<DriverPeriodResource[]>
> => {
  return client.get<DriverPeriodResource[]>('/api/Drivers/GetDealPeriods');
};
export function getDriversWithUnsignedDeal(): Promise<void> {
  return client.get('/Drivers/ReminderDriversWithUnsignedDeals');
}

export function getDriversByShift(
  props: IFilters
): Promise<AxiosResponse<DriverResource[]>> {
  return client.post<DriverResource[]>('/api/Drivers/GetDriversByShift', {
    startDate: formatDateApi(props.startDate),
    endDate: formatDateApi(props.endDate),
    shiftNumbers: props.shifts.map((a) => a.id),
    includeSettled: props.includeSettled,
  });
}

export function signDriverOvertimeDeal(
  props: SignOvertimeDealInput
): Promise<AxiosResponse> {
  return client.patch('/api/Drivers/SignDriverOvertimeDeal', props);
}
