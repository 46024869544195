import { ElementType, Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import AuthGuard from 'components/AuthGuard';
import DashboardLayout from 'src/pages/shared/DashboardLayout';
import LoadingScreen from 'components/LoadingScreen';
import RequireAuth from 'components/RequireAuth ';
import { UserProfiles } from 'types/userProfiles';
import { LoginType } from 'src/types/loginData';
import ErrorBoundary from 'src/pages/ErrorBoundary';

// eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    </Suspense>
  );
// Home
const Home = Loadable(lazy(() => import('./pages/dashboard/HomePage')));
// Authentication pages

// const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
// const LoginInspector = Loadable(
//   lazy(() => import('./pages/authentication/LoginInspector'))
// );

const Profile = Loadable(lazy(() => import('./pages/authentication/Profile')));

// Deal pages

const HHEEDealPage = Loadable(lazy(() => import('./pages/deals/DealPage')));
const DealDriverPage = Loadable(
  lazy(() => import('./pages/deals/DealDriverPage'))
);

// Report pages
const Attendance = Loadable(
  lazy(() => import('./pages/attendance/AttendancePage'))
);
const DailyAttendance = Loadable(
  lazy(() => import('./pages/dailyAttendance/DailyAttendancePage'))
);

const SundaysAndHolidays = Loadable(
  lazy(() => import('./pages/sundaysAndHolidays/SundaysAndHolidaysPage'))
);

const ModifiedProgramming = Loadable(
  lazy(() => import('./pages/programming/ModifiedProgrammingPage'))
);
const Programming = Loadable(
  lazy(() => import('./pages/programming/ProgrammingPage'))
);
const ShiftAttendance = Loadable(
  lazy(() => import('./pages/routeSheet/RouteSheetPage'))
);

const CommentShiftAttendance = Loadable(
  lazy(() => import('./pages/routeSheet/CommentShiftAttendancePage'))
);

const DriverSummary = Loadable(
  lazy(() => import('./pages/attendanceSummary/AttendanceSummaryPage'))
);
const EmailStatus = Loadable(
  lazy(() => import('./pages/emailStatusReport/EmailStatusReportPage'))
);

const IncidentReport = Loadable(
  lazy(() => import('./pages/incidentReport/IncidentReportPage'))
);

// SearchAttendance Page
const SearchAttendance = Loadable(
  lazy(() => import('./pages/search/SearchAttendancePage'))
);

// UserAccount Page
const UserAccount = Loadable(
  lazy(() => import('./pages/userAccount/UserAccountPage'))
);

const DailyControl = Loadable(
  lazy(() => import('./pages/dailyControl/DailyControlPage'))
);

// Inspector selection Page
const InspectorAccess = Loadable(
  lazy(() => import('./pages/inspectorAccess/InspectorAccessPage'))
);

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import('./pages/AuthorizationRequired'))
);
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const routes: RouteObject[] = [
  {
    path: 'inspectorDT',
    element: (
      <AuthGuard type={LoginType.INSPECTOR}>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/inspectorDT',
        element: (
          <RequireAuth allowedProfiles={[UserProfiles.Inspector]}>
            <InspectorAccess />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthGuard type={LoginType.NORMAL}>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'userAccounts',
        element: (
          <RequireAuth
            allowedProfiles={[UserProfiles.Admin, UserProfiles.Administrativo]}
          >
            <UserAccount />
          </RequireAuth>
        ),
      },
      {
        path: 'searchAttendance',
        element: <SearchAttendance />,
      },
      {
        path: 'annexes',
        children: [
          {
            path: 'programming',
            element: <Programming />,
          },
          {
            path: 'shiftAttendance',
            element: <ShiftAttendance />,
          },
          {
            path: 'commentShiftAttendance',
            element: <CommentShiftAttendance />,
          },
          {
            path: 'driverSummary',
            element: <DriverSummary />,
          },
        ],
      },
      {
        path: 'reports',
        children: [
          {
            path: 'attendance',
            element: <Attendance />,
          },
          {
            path: 'dailyAttendance',
            element: <DailyAttendance />,
          },
          {
            path: 'sundaysandholidays',
            element: <SundaysAndHolidays />,
          },
          {
            path: 'modifiedprogramming',
            element: <ModifiedProgramming />,
          },
          {
            path: 'emailStatus',
            element: <EmailStatus />,
          },
          {
            path: 'incidentReport',
            element: <IncidentReport />,
          },
          {
            path: 'dailyControl',
            element: (
              <RequireAuth
                allowedProfiles={[
                  UserProfiles.Admin,
                  UserProfiles.Administrativo,
                  UserProfiles.Inspector,
                ]}
              >
                <DailyControl />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: 'deals',
        children: [
          {
            path: 'deal',
            element: <HHEEDealPage />,
          },
          {
            path: 'driver/:id',
            element: <DealDriverPage />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: (
      <AuthGuard type={LoginType.NORMAL}>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
