import { ThemeOptions } from '@mui/material/styles';
import { darkShadows, lightShadows } from 'src/theme/shadows';

export const metbusLight: ThemeOptions = {
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 0.86,
            color: '#42526e',
          },
        },
      },
    },
  },
  palette: {
    mode: 'light',
    action: {
      active: '#6b778c',
    },
    background: {
      default: '#f4f5f7',
      paper: '#FFFFFF',
    },
    error: {
      contrastText: '#ffffff',
      main: '#f44336',
    },
    success: {
      contrastText: '#ffffff',
      main: '#4caf50',
    },
    primary: {
      main: '#0093b3',
      light: '#33a9c2',
    },
    secondary: {
      main: '#f59e24',
      light: '#fdecd3',
      dark: '#c47e1d',
    },
    text: {
      secondary: 'rgba(0,0,0,0.5)',
      primary: '#0a1b1e',
      disabled: 'rgba(0,0,0,0.3)',
    },
  },
  shadows: lightShadows,
  // typography: {
  //   fontFamily: 'Raleway',
  //   button: {
  //     fontWeight: 600,
  //   },
  //   h1: {
  //     fontWeight: 600,
  //     fontSize: '3.5rem',
  //   },
  //   h2: {
  //     fontWeight: 600,
  //     fontSize: '3rem',
  //   },
  //   h3: {
  //     fontWeight: 600,
  //     fontSize: '2.25rem',
  //   },
  //   h4: {
  //     fontWeight: 600,
  //     fontSize: '2rem',
  //   },
  //   h5: {
  //     fontWeight: 600,
  //     fontSize: '1.5rem',
  //   },
  //   h6: {
  //     fontWeight: 600,
  //     fontSize: '1.125rem',
  //   },
  //   overline: {
  //     fontWeight: 600,
  //   },
  // },
};

export const metbusDark: ThemeOptions = {
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          '& .MuiTableCell-root': {
            backgroundColor: '#2A2F33',
          },
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#0093b3',
      contrastText: '#ffffff',
      light: '#33a9c2',
    },
    background: {
      default: '#0B161E',
      paper: '#0E1012',
    },
    secondary: {
      main: '#f59e24',
      contrastText: 'rgba(0,0,0,0.87)',
      light: '#fdecd3',
      dark: '#c47e1d',
    },
    text: {
      secondary: 'rgba(255,255,255,0.5)',
      primary: '#ffffff',
      disabled: 'rgba(255,255,255,0.3)',
    },
    error: {
      // main: '#f44336',
      // contrastText: '#ffffff',
      contrastText: '#ffffff',
      main: '#f44336',
    },
    warning: {
      // main: '#ff9800',
      // contrastText: 'rgba(0,0,0,0.87)',
      contrastText: '#ffffff',
      main: '#ff9800',
    },
    info: {
      main: '#2196f3',
      contrastText: '#ffffff',
    },
    success: {
      // main: '#4caf50',
      // contrastText: 'rgba(0,0,0,0.87)',
      contrastText: '#ffffff',
      main: '#4caf50',
    },
    divider: 'rgba(255,255,255,0.12)',
  },
  shadows: darkShadows,
  // typography: {
  //   fontFamily: 'Raleway',
  //   button: {
  //     fontWeight: 600,
  //   },
  //   h1: {
  //     fontWeight: 600,
  //     fontSize: '3.5rem',
  //   },
  //   h2: {
  //     fontWeight: 600,
  //     fontSize: '3rem',
  //   },
  //   h3: {
  //     fontWeight: 600,
  //     fontSize: '2.25rem',
  //   },
  //   h4: {
  //     fontWeight: 600,
  //     fontSize: '2rem',
  //   },
  //   h5: {
  //     fontWeight: 600,
  //     fontSize: '1.5rem',
  //   },
  //   h6: {
  //     fontWeight: 600,
  //     fontSize: '1.125rem',
  //   },
  //   overline: {
  //     fontWeight: 600,
  //   },
  // },
};
